
import { computed, ComputedRef, defineComponent, onMounted, ref, watch } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

import {
  useWorks,
  useDeleteWork,
  useTakeWorkDown,
  usePublishWork
} from '@/composables/api';

import {
  DEFAULT_PAGE_SIZE,
  DELETED_SUCCESSFULLY_TEXT,
  getAgents,
  getClassifications,
  getGenres,
  getTags,
  IntBoolean,
  MAX_PER_PAGE,
  ResponseError,
  WorkIdOption
} from '@/services/api';
import SearchBox from '@/components/keyword-searching/Index.vue';
import { formatLocalTime } from '@/utils/format-time';
import { Agent, Classification, Genre, Tag, Work, WorkStatus } from '@/interfaces';
import { getStatusTagType } from '@/utils/render';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import dayjs from 'dayjs';
import permissionUnits from '@/components/permission-units/index.vue';
import { useRoute, useRouter } from 'vue-router';
import { isEmpty, join, map } from 'lodash';
import { useKeyword, usePage } from '@/composables';

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    permissionUnits
  },
  setup() {
    const lang = ref('all');
    const statusIndex = ref(undefined);
    const { keyword } = useKeyword();

    const isSeries = ref(IntBoolean.FALSE);
    const status = ref();
    const classifications = ref<Classification[]>([]);
    const classificationIds = ref();
    const classificationOptions = computed(() => classifications.value.map(({ name, id }) => ({ label: `${name}`, value: `${id}` })));
    const agentId = ref();
    const agents = ref<Agent[]>([]);
    const agentOptions = computed(() => agents.value.map(({ name, id }) => ({ label: `${name}`, value: `${id}` })));
    const genreId = ref();
    const genres = ref<Genre[]>([]);
    const genreOptions = computed(() => genres.value.map(({ name, id }) => ({ label: `${name}`, value: `${id}` })));
    const tagId = ref();
    const tags = ref<Tag[]>([]);
    const tagOptions = computed(() => tags.value.map(({ name, id }) => ({ label: `${name}`, value: `${id}` })));

    const router = useRouter();
    const route = useRoute();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);
    const { data, isLoading, isFetching, refetch } = useWorks({
      page,
      keyword,
      isSeries,
      pageSize,
      status,
      agentId,
      genreId,
      tagId,
      classificationIds,
      lang
    });
    const { isLoading: isDeleting, mutate } = useDeleteWork();
    const { isLoading: isTakingWorkDownLoading, mutate: takeWorkDown } = useTakeWorkDown();
    const { isLoading: isPublishWorkLoading, mutate: publishWork } = usePublishWork();

    const fetchClassifications = async() => {
      classifications.value = (await getClassifications({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const fetchAgents = async() => {
      agents.value = (await getAgents({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const fetchGenres = async() => {
      genres.value = (await getGenres({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const fetchTags = async() => {
      tags.value = (await getTags({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      agentId.value = event[0];
      classificationIds.value = event[1];
      genreId.value = event[2];
      tagId.value = event[3];
      status.value = event[4];
    };

    const handleTakeWorkDown = (workId: number | string, index: Number) => {
      statusIndex.value = index;
      takeWorkDown({ workId }, {
        onSuccess() {
          refetch.value();
          ElMessage.success('下架成功');
        },
        onError(error: ResponseError) {
          ElMessage.error(error.response?.data.message);
        }
      });
    };

    const handlePublishWork = (workId: number | string, index: Number) => {
      statusIndex.value = index;
      publishWork({ workId }, {
        onSuccess() {
          refetch.value();
          ElMessage.success('上架成功');
        },
        onError(error: ResponseError) {
          ElMessage.error(error.response?.data.message);
        }
      });
    };

    const checkStatusIsComingSoon = (work: Work) => {
      const publishDateTimestamp = dayjs(work.publishedAt);
      const now = dayjs();
      return (publishDateTimestamp > now) && (work.status === WorkStatus.PUBLISHED);
    };

    const filterOptions: ComputedRef<FilterOption[]> = computed(() => {
      return [
        {
          type: FilterType.SELECTOR,
          label: '代理商',
          placeholder: '請選擇',
          options: agentOptions.value
        },
        {
          type: FilterType.MULTI_SELECTOR,
          label: '主類型',
          placeholder: '請選擇',
          options: classificationOptions.value
        },
        {
          type: FilterType.SELECTOR,
          label: '影片類型',
          placeholder: '請選擇',
          options: genreOptions.value
        },
        {
          type: FilterType.SELECTOR,
          label: '標籤',
          placeholder: '請選擇',
          options: tagOptions.value
        },
        {
          type: FilterType.SELECTOR,
          label: '狀態',
          placeholder: '請選擇',
          options: [
            { label: '草稿', value: 'draft' },
            { label: '處理中', value: 'processing' },
            { label: '上架', value: 'published' },
            { label: '下架', value: 'taken_down' },
            { label: '已到期', value: 'expired' }
          ]
        }
      ];
    });

    const deleteWork = ({ workId }: WorkIdOption, index: Number) => {
      statusIndex.value = index;
      ElMessageBox.confirm(
        '是否確認要刪除？',
        '警告',
        {
          confirmButtonText: '刪除',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          mutate({ workId }, {
            onSuccess() {
              refetch.value();

              ElMessage.success(DELETED_SUCCESSFULLY_TEXT);
            },
            onError(error: ResponseError) {
              ElMessage.error(error.response?.data.message);
            }
          });
        })
        .catch();
    };

    /** 取得所有主類型文字 */
    const getClassificationsText = (classifications: Classification[]): string => {
      if (isEmpty(classifications)) {
        return '-';
      }
      return join(map(classifications, c => c.name), '、');
    };

    onMounted(() => {
      fetchClassifications();
      fetchAgents();
      fetchGenres();
      fetchTags();
    });

    return {
      page,
      data,
      isLoading,
      isFetching,
      WorkStatus,
      statusIndex,
      filterOptions,
      isDeleting,
      isPublishWorkLoading,
      isTakingWorkDownLoading,
      keyword,
      deleteWork,
      formatLocalTime,
      getStatusTagType,
      handlePublishWork,
      handleTakeWorkDown,
      handleFilterChange,
      getClassificationsText,
      checkStatusIsComingSoon,
      setPageQuery
    };
  }
});
