
import { computed, defineComponent, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import { useAgents } from '@/composables/api';
import { DEFAULT_PAGE_SIZE, createAgent, updateAgent, deleteAgent, ResponseError } from '@/services/api';
import permissionUnits from '@/components/permission-units/index.vue';
import SearchBox from '@/components/keyword-searching/Index.vue';
import { formatLocalTime } from '@/utils/format-time';
import { Agent } from '@/interfaces';
import { usePage, useKeyword } from '@/composables';

type AgentAction = 'create' | 'update' | 'delete';

export default defineComponent({
  components: {
    permissionUnits,
    SearchBox
  },
  setup() {
    const { keyword } = useKeyword();
    const router = useRouter();
    const route = useRoute();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);
    const { data, isLoading, isFetching, refetch } = useAgents({ page, pageSize, keyword });

    const currentAction = ref<AgentAction>('create');
    const selectedAgent = ref<Partial<Agent>>({});
    const agentName = ref('');

    const isDialogVisible = ref(false);
    const isDialogLoading = ref(false);
    const dialogTitle = computed(() => {
      if (currentAction.value === 'create') {
        return '新增代理商';
      }
      if (currentAction.value === 'update') {
        return '編輯代理商';
      }
      return `是否確認刪除 ${selectedAgent.value.id}-${selectedAgent.value.name} 代理商？刪除前請先移除此代理商的所有影片或將影片更換代理商`;
    });

    const handleShowDialog = ({ agent, action }: { agent?: Agent, action: AgentAction }) => {
      if (action === 'create') {
        agentName.value = '';
        selectedAgent.value = {};
      }
      if (['update', 'delete'].includes(action)) {
        agentName.value = agent.name;
        selectedAgent.value = agent;
      }
      currentAction.value = action;
      isDialogVisible.value = true;
    };

    /** 關閉彈窗 */
    const handleCloseDialog = () => {
      agentName.value = '';
      selectedAgent.value = {};
      isDialogVisible.value = false;
    };

    const handleSubmit = async() => {
      try {
        isDialogLoading.value = true;

        if (currentAction.value === 'create') {
          await createAgent({ data: { name: agentName.value } });
        }
        if (currentAction.value === 'update') {
          await updateAgent({ query: { id: selectedAgent.value?.id }, data: { name: agentName.value } });
        }
        if (currentAction.value === 'delete') {
          await deleteAgent({ id: selectedAgent.value?.id });
        }

        handleCloseDialog();
        refetch.value();
      } catch (error) {
        ElMessage.error((error as ResponseError).response?.data.message);
      } finally {
        isDialogLoading.value = false;
      }
    };

    return {
      formatLocalTime,
      page,
      keyword,
      isLoading,
      isFetching,
      data,
      agentName,
      dialogTitle,
      currentAction,
      isDialogVisible,
      isDialogLoading,
      handleShowDialog,
      handleCloseDialog,
      handleSubmit,
      setPageQuery
    };
  }
});
